export default {
  siteTitle: 'Get bonus',
  blogerName: 'FEDYA BOT',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/ludogizn',
    },
    {
      name: 'telegram',
      url: 'https://t.me/ludogiznchat',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@ZANOSIBABLO/featured',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/fedor_zanos',
    },
    {
      name: 'trovo',
      url: 'https://trovo.live/FEDYA_BOTIK',
    },
    {
      name: 'tiktok',
      url: 'https://www.tiktok.com/@fedya_botik',
    },
    {
      name: 'kick',
      url: 'https://kick.com/fedya_botik',
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c00b7e3c3',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/cc7b08834',
      gameTitle: 'Royal High-Road (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c5a0ab7e6',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/caeca0dc4',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c7e44550f',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c19fe363d',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cc576f05f',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c7bed47cf',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c506b7062',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c9148a205',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>AURA</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'AURA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  counter: true,
  bonus100: 'Bônus 100%',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
